<template>
    <div class="row table-sm mr-0 ml-0 p-0">
        <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
            <div
                class="
                    col-7
                    p-0
                    align-self-center
                    font-weight-bold
                    d-flex
                    align-items-center
                "
            >
                <h5 class="d-inline mr-2 font-weight-bold">
                    {{ $t("message.resourcesText") }}
                </h5>
                <crm-refresh @c-click="refresh()"></crm-refresh>
                <div class="text-center d-flex sorddata ml-3">
                    <el-input
                        size="mini"
                        :placeholder="$t('message.search')"
                        prefix-icon="el-icon-search"
                        v-model="filterForm.search"
                        clearable
                    ></el-input>
                </div>
            </div>

            <div
                class="
                    col-5
                    align-items-center align-self-center
                    text-right
                    pr-0
                    d-flex
                    justify-content-end
                "
            >
                <el-button size="mini" @click="appModal = true">
                    <i class="el-icon-circle-plus-outline"></i>
                    {{ $t("message.create") }}
                </el-button>
                <export-excel
                    class="btn excel_btn"
                    :data="list"
                    @fetch="controlExcelData()"
                    worksheet="Ресурсы"
                    name="Ресурсы.xls"
                >
                    <el-button size="mini">
                        <i class="el-icon-document-delete"></i> Excel
                    </el-button>
                </export-excel>
                <crm-column-settings
                    :columns="columns"
                    :modelName="'costs'"
                ></crm-column-settings>
            </div>
        </div>

        <table class="table table-bordered table-hover">
            <thead>
                <tr>
                    <th class="w50p" v-if="columns.id.show">
                        {{ columns.id.title }}
                    </th>

                    <th v-if="columns.resName.show">
                        {{ columns.resName.title }}
                    </th>

                    <th v-if="columns.miniName.show">
                        {{ columns.miniName.title }}
                    </th>

                    <th v-if="columns.dataContract.show">
                        {{ columns.dataContract.title }}
                    </th>

                    <th v-if="columns.data.show">
                        {{ columns.data.title }}
                    </th>

                    <th v-if="columns.settings.show">
                        {{ columns.settings.title }}
                    </th>
                </tr>

                <tr class="filter_sorche">
                    <th v-if="columns.id.show">
                        <el-input
                            clearable
                            size="mini"
                            v-model="filterForm.id"
                            :placeholder="columns.id.title"
                            class="id_input"
                        ></el-input>
                    </th>

                    <th v-if="columns.resName.show">
                        <el-input
                            clearable
                            size="mini"
                            v-model="filterForm.resName"
                            :placeholder="columns.resName.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.miniName.show">
                        <el-input
                            clearable
                            size="mini"
                            v-model="filterForm.miniName"
                            :placeholder="columns.miniName.title"
                        ></el-input>
                    </th>

                    <th v-if="columns.dataContract.show">
                        <el-date-picker
                            :placeholder="columns.dataContract.title"
                            v-model="filterForm.dataContract"
                            size="mini"
                        >
                        </el-date-picker>
                    </th>

                    <th v-if="columns.data.show">
                        <el-date-picker
                            :placeholder="columns.data.title"
                            v-model="filterForm.data"
                            size="mini"
                        >
                        </el-date-picker>
                    </th>

                    <th class="settinW" v-if="columns.settings.show"></th>
                </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="user in list" :key="user.id" class="cursor-pointer">
                    <td v-if="columns.id.show">{{ user.id }}</td>

                    <td v-if="columns.resName.show">
                        {{ user.resName }}
                    </td>

                    <td v-if="columns.miniName.show">
                        {{ user.miniName }}
                    </td>

                    <td v-if="columns.dataContract.show">
                        {{ user.dataContract }}
                    </td>

                    <td v-if="columns.data.show">{{ user.data }}</td>

                    <td v-if="columns.settings.show" class="settings-td">
                        <el-dropdown
                            :hide-on-click="false"
                            class="table-bottons-right"
                        >
                            <span class="el-dropdown-link">
                                <i
                                    class="
                                        el-icon-arrow-down el-icon-more-outline
                                    "
                                ></i>
                            </span>
                            <el-dropdown-menu
                                slot="dropdown"
                                class="dropdown-menumy-style"
                            >
                                <el-dropdown-item icon="el-icon-edit">
                                    Изменить
                                </el-dropdown-item>
                                <el-dropdown-item icon="el-icon-delete">
                                    Удалить
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </td>
                </tr>
            </transition-group>
        </table>

        <el-drawer
            class="right-modal"
            :with-header="false"
            :visible.sync="appModal"
            size="80%" :wrapperClosable="false"
        >
            <CrmCreate />
        </el-drawer>
    </div>
</template>

<script>
// @ is an alias to /src
import CrmCreate from "./components/crm-create";
export default {
    name: "resources",
    components: {
        CrmCreate,
    },

    data() {
        return {
            dialogTableVisible: false,
            appModal: false,
            sarcheBlocks: "",
            filterForm: {
                id: "",
                resName: "",
                miniName: "",
                dataContract: "",
                data: "",
            },
            list: [
                {
                    id: "1",
                    resName: "Nome 1",
                    miniName: "Simae",
                    dataContract: "02.05.1995",
                    data: "12.12.2020",
                },
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                resName: {
                    show: true,
                    title: "Наименование",
                    sortable: true,
                    column: "resName",
                },

                miniName: {
                    show: true,
                    title: "Короткое название",
                    sortable: true,
                    column: "miniName",
                },

                dataContract: {
                    show: true,
                    title: "Дата создания ",
                    sortable: true,
                    column: "dataContract",
                },
                data: {
                    show: true,
                    title: "Изменено ",
                    sortable: true,
                    column: "data",
                },

                settings: {
                    show: true,
                    title: "Профиль",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
};
</script>

